<template>
  <div id="radialbaroptions">
    <h4>Start Angle</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radialBar.startAngle"
        :max="360"
        :min="-360"
        show-input
      ></el-slider>
    </div>

    <h4>End Angle</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radialBar.endAngle"
        :max="360"
        :min="-360"
        show-input
      ></el-slider>
    </div>

    <h4>Left Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radialBar.offsetX"
        :max="200"
        :min="-200"
        show-input
      ></el-slider>
    </div>
    <h4>Top Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radialBar.offsetY"
        :max="200"
        :min="-200"
        show-input
      ></el-slider>
    </div>

    <el-collapse class="mt3">
      <el-collapse-item title="Hollow">
        <h4>Margin</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.hollow.margin"
            :max="50"
            :min="0"
            show-input
          ></el-slider>
        </div>

        <h4>Size</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.hollow.size"
            :max="100"
            :min="0"
            show-input
          ></el-slider>
        </div>

        <el-form-item class="mt3" label="Hollow Background Color">
          <extended-el-color-picker
            @change="updateChart"
            v-model="plotOptions.radialBar.hollow.background"
          ></extended-el-color-picker>
        </el-form-item>

        <el-collapse class="mt3">
          <el-collapse-item title="Hollow DropShadow">
            <el-form-item label="Enable">
              <el-switch
                inactive-color="#24272b"
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.enabled"
              ></el-switch>
            </el-form-item>

            <h4>Blur</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.blur"
                :max="30"
              ></el-slider>
            </div>

            <h4>Offset X</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.left"
                :min="-30"
                :max="30"
              ></el-slider>
            </div>

            <h4>Offset Y</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.top"
                :min="-30"
                :max="30"
              ></el-slider>
            </div>

            <el-form-item label="Shadow Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Shadow Opacity</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.hollow.dropShadow.opacity"
                :step="0.05"
                :min="0"
                :max="1"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>

    <el-collapse>
      <el-collapse-item title="Track">
        <el-form-item label="Show">
          <el-switch
            inactive-color="#24272b"
            @change="updateChart"
            v-model="plotOptions.radialBar.track.show"
          ></el-switch>
        </el-form-item>

        <h4>Start Angle</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.track.startAngle"
            :max="360"
            :min="-360"
            show-input
          ></el-slider>
        </div>

        <h4>End Angle</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.track.endAngle"
            :max="360"
            :min="-360"
            show-input
          ></el-slider>
        </div>

        <h4>Track Width (0% - 100%)</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.track.strokeWidth"
            :max="100"
            :min="0"
            show-input
          ></el-slider>
        </div>

        <h4>Margin</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.track.margin"
            :max="50"
            :min="0"
            show-input
          ></el-slider>
        </div>

        <h4>Opacity</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="plotOptions.radialBar.track.opacity"
            :max="1"
            :min="0"
            :step="0.1"
            show-input
          ></el-slider>
        </div>

        <el-form-item class="mt3" label="Track Background Color">
          <extended-el-color-picker
            @change="updateChart"
            v-model="plotOptions.radialBar.track.background"
          ></extended-el-color-picker>
        </el-form-item>

        <el-collapse class="mt3">
          <el-collapse-item title="Track DropShadow">
            <el-form-item label="Enable">
              <el-switch
                inactive-color="#24272b"
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.enabled"
              ></el-switch>
            </el-form-item>

            <h4>Blur</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.blur"
                :max="30"
              ></el-slider>
            </div>

            <h4>Offset X</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.left"
                :min="-30"
                :max="30"
              ></el-slider>
            </div>

            <h4>Offset Y</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.top"
                :min="-30"
                :max="30"
              ></el-slider>
            </div>

            <el-form-item label="Shadow Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Shadow Opacity</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.track.dropShadow.opacity"
                :step="0.05"
                :min="0"
                :max="1"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>

    <el-collapse>
      <el-collapse-item title="Inner Data Labels">
        <el-tooltip
          class="item"
          effect="dark"
          content="Show inner labels when user hovers over the donut slices"
          placement="left-start"
        >
          <el-form-item label="Show">
            <el-switch
              @change="updateChart"
              inactive-color="#24272b"
              v-model="plotOptions.radialBar.dataLabels.show"
            ></el-switch>
          </el-form-item>
        </el-tooltip>

        <el-collapse>
          <el-collapse-item title="Category / Name">
            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.name.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.name.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.name.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-collapse>
          <el-collapse-item title="Value">
            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.value.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.value.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.value.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-collapse>
          <el-collapse-item title="Total">
            <el-form-item label="Show Total">
              <el-switch
                @change="updateChart"
                inactive-color="#24272b"
                v-model="plotOptions.radialBar.dataLabels.total.show"
              ></el-switch>
            </el-form-item>

            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.total.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.total.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.radialBar.dataLabels.total.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>

    <el-collapse>
      <el-collapse-item title="Padding">
        <h4>Top</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.top"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Right</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.right"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Bottom</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.bottom"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Left</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.left"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: 50,
            background: "#fff",
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            startAngle: undefined,
            endAngle: undefined,
            background: "#f2f2f2",
            strokeWidth: 97,
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: 16,
              color: undefined,
              offsetY: 0,
            },
            value: {
              show: true,
              fontSize: 14,
              color: undefined,
              offsetY: 16,
            },
            total: {
              show: false,
              label: "Total",
              fontSize: 16,
              color: undefined,
            },
          },
        },
      },
    };
  },
  props: {
    grid: {
      type: Object,
      required: true,
    },
  },
  watch: {
    chartConfig: function(newProp) {
      const p = this.plotOptions;
      this.setNewOptions(p);
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        radialBar: {
          startAngle: p.radialBar.startAngle,
          endAngle: p.radialBar.endAngle,
          offsetX: p.radialBar.offsetX,
          offsetY: p.radialBar.offsetY,
          hollow: {
            margin: p.radialBar.hollow.margin,
            size: parseInt(p.radialBar.hollow.size),
            background: p.radialBar.hollow.background,
            position: p.radialBar.hollow.position,
            dropShadow: {
              enabled: p.radialBar.hollow.dropShadow.enabled,
              top: p.radialBar.hollow.dropShadow.top,
              left: p.radialBar.hollow.dropShadow.left,
              blur: p.radialBar.hollow.dropShadow.blur,
              color: p.radialBar.hollow.dropShadow.colors,
              opacity: p.radialBar.hollow.dropShadow.opacity,
            },
          },
          track: {
            show: p.radialBar.track.show,
            startAngle: p.radialBar.track.startAngle,
            endAngle: p.radialBar.track.endAngle,
            background: p.radialBar.track.background,
            strokeWidth: parseInt(p.radialBar.track.strokeWidth),
            opacity: p.radialBar.track.opacity,
            margin: p.radialBar.track.margin,
            dropShadow: {
              enabled: p.radialBar.track.dropShadow.enabled,
              top: p.radialBar.track.dropShadow.top,
              left: p.radialBar.track.dropShadow.left,
              blur: p.radialBar.track.dropShadow.blur,
              color: p.radialBar.track.dropShadow.color,
              opacity: p.radialBar.track.dropShadow.opacity,
            },
          },
          dataLabels: {
            show: p.radialBar.dataLabels.show,
            name: {
              show: p.radialBar.dataLabels.name.show,
              fontSize: p.radialBar.dataLabels.name.fontSize,
              fontWeight: p.radialBar.dataLabels.name.fontWeight,
              color: p.radialBar.dataLabels.name.colors,
              offsetY: p.radialBar.dataLabels.name.offsetY,
            },
            value: {
              show: p.radialBar.dataLabels.value.show,
              fontSize: p.radialBar.dataLabels.value.fontSize,
              fontWeight: p.radialBar.dataLabels.value.fontWeight,
              color: p.radialBar.dataLabels.value.color,
              offsetY: p.radialBar.dataLabels.value.offsetY,
            },
            total: {
              show: p.radialBar.dataLabels.total.show,
              label: p.radialBar.dataLabels.total.label,
              fontSize: p.radialBar.dataLabels.total.fontSize,
              fontWeight: p.radialBar.dataLabels.total.fontWeight,
              color: p.radialBar.dataLabels.total.color,
            },
          },
        },
      };
    },

    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        grid: {
          padding: {
            top: this.grid.padding.top,
            left: this.grid.padding.left,
            right: this.grid.padding.right,
            bottom: this.grid.padding.bottom,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: p.radialBar.startAngle,
            endAngle: p.radialBar.endAngle,
            offsetX: p.radialBar.offsetX,
            offsetY: p.radialBar.offsetY,
            hollow: {
              margin: p.radialBar.hollow.margin,
              size: p.radialBar.hollow.size.toString() + "%",
              background: p.radialBar.hollow.background,
              position: p.radialBar.hollow.position,
              dropShadow: {
                enabled: p.radialBar.hollow.dropShadow.enabled,
                top: p.radialBar.hollow.dropShadow.top,
                left: p.radialBar.hollow.dropShadow.left,
                blur: p.radialBar.hollow.dropShadow.blur,
                color: p.radialBar.hollow.dropShadow.colors,
                opacity: p.radialBar.hollow.dropShadow.opacity,
              },
            },
            track: {
              show: p.radialBar.track.show,
              startAngle: p.radialBar.track.startAngle,
              endAngle: p.radialBar.track.endAngle,
              background: p.radialBar.track.background,
              strokeWidth: p.radialBar.track.strokeWidth.toString() + "%",
              opacity: p.radialBar.track.opacity,
              margin: p.radialBar.track.margin,
              dropShadow: {
                enabled: p.radialBar.track.dropShadow.enabled,
                top: p.radialBar.track.dropShadow.top,
                left: p.radialBar.track.dropShadow.left,
                blur: p.radialBar.track.dropShadow.blur,
                color: p.radialBar.track.dropShadow.color,
                opacity: p.radialBar.track.dropShadow.opacity,
              },
            },
            dataLabels: {
              show: p.radialBar.dataLabels.show,
              name: {
                show: p.radialBar.dataLabels.name.show,
                fontSize: p.radialBar.dataLabels.name.fontSize,
                fontWeight: p.radialBar.dataLabels.name.fontWeight,
                color: p.radialBar.dataLabels.name.colors,
                offsetY: p.radialBar.dataLabels.name.offsetY,
              },
              value: {
                show: p.radialBar.dataLabels.value.show,
                fontSize: p.radialBar.dataLabels.value.fontSize,
                fontWeight: p.radialBar.dataLabels.value.fontWeight,
                color: p.radialBar.dataLabels.value.color,
                offsetY: p.radialBar.dataLabels.value.offsetY,
              },
              total: {
                show: p.radialBar.dataLabels.total.show,
                label: p.radialBar.dataLabels.total.label,
                fontSize: p.radialBar.dataLabels.total.fontSize,
                fontWeight: p.radialBar.dataLabels.total.fontWeight,
                color: p.radialBar.dataLabels.total.color,
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
